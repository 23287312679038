import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from './views/Home.vue'
import FormularioCotizacion from './views/cotizacion/Formulario.vue'
import HomePrivado from './views/privado/HomePrivadoMo.vue'
import LoginMo from './views/login/loginMo.vue'
import Login2Mo from './views/login/login2Mo.vue'
import Recuperacion from './views/login/Recuperacion.vue'
import MiCuentaRec from './views/login/MiCuentaRec.vue'
import MiCuenta from './views/login/MiCuentaMo.vue'
import Contacto from './views/pages/Contacto.vue'
import NosotrosMo from './views/pages/NosotrosMo.vue'
import PreguntasMo from './views/pages/PreguntasMo.vue'


import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
   
    {
      name: 'Home',
      path: '/',
      component: Home,
    }, 
    {
      name: 'formulario-cotizacion',
      path: '/formulario-cotizacion',
      component: FormularioCotizacion,
    },  
    {
      name: 'login',
      path: '/login',
      component: LoginMo,
    },
    {
      name: 'login2',
      path: '/login2',
      component: Login2Mo,
    },
    {
      name: 'recuperacion',
      path: '/recuperacion',
      component: Recuperacion,
    },
    {
      name: 'mi-cuenta-recupera',
      path: '/mi-cuenta-recupera/:token',
      component: MiCuentaRec,
    },
    {
      name: 'mi-cuenta-activa',
      path: '/mi-cuenta-activa/:token',
      component: MiCuenta,
    },
    {
      name: 'mi-cuenta',
      path: '/mi-cuenta',
      component: HomePrivado,
    },
   
    {
      name: 'contacto',
      path: '/contacto',
      component: Contacto,
    },
    {
      name: 'nosotros',
      path: '/nosotros',
      component: NosotrosMo,
    },
    {
      name: 'preguntas',
      path: '/preguntas',
      component: PreguntasMo,
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: autorizacionAdmin,
    },
  
    {
      path: '*',
      component: Home
    }
  ],
})

function autorizacionAdmin(to, from, next) {
  let token = JSON.parse(localStorage.getItem("token"));
  if (token) {
    localStorage.removeItem("token");
    try {
      const response = auth.logout();
      location.href="/";
    } catch (error) {
      console.log(error);
    }
  }
}
