<template>
    <!-- <a href="https://wa.me/56934465033?text=hola" class="whatsapp-button" target="_blank">
        <img src="/assets/WhatsApp.svg" alt="WhatsApp" class="whatsapp-icon" width="30" height="30">
    </a> -->
</template>

<script>
export default {
  name: "WhatsAppButton",
  mounted() {
    if (!document.querySelector(`script[src="https://relif-saas-back-yb2ukoflca-tl.a.run.app/whatsapp/button-script?businessUnitId=39&phoneNumber=56985080245&message=Hola,%20quiero%20ayuda%20con%20mi%20seguro"]`)) {
      const script = document.createElement("script");
      script.src = "https://relif-saas-back-yb2ukoflca-tl.a.run.app/whatsapp/button-script?businessUnitId=39&phoneNumber=56985080245&message=Hola,%20quiero%20ayuda%20con%20mi%20seguro";
      script.async = true;
      script.onerror = () => {
        console.error("Error: No se pudo cargar el script de WhatsApp desde la URL proporcionada.");
      };
      document.body.appendChild(script);
    }
  }
};
</script>

<style scoped>
.whatsapp-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 15px;
    right: 15px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    transition: background-color 0.3s;
}

.whatsapp-button:hover {
    background-color: #128c7e;
}

.whatsapp-icon {
    margin-top: 15px;
}
@media only screen and (max-width: 800px) {
    .whatsapp-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 30px !important;
    right: 30px !important;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    transition: background-color 0.3s;
}
}
</style>