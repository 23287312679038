<template>
    <v-row class="row3 ">
      <v-col cols="12" sm="12" md="6" lg="3" xl="3 " style="display: flex; justify-content: center; ">
        <v-row class="row3 " style="display: flex; justify-content: center; ">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
            
                    <v-row >
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6 " style="display: flex; justify-content: center; ">
                           <div class="una-empresa-de">Una empresa de</div>
                        </v-col>
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6 " style="display: flex; justify-content: center; ">
                           <img class="logotipo-grupo-insurex-blanco-1" src="/assets/logotipo-grupo-insurex-blanco-10.png">
                        </v-col>
                    </v-row>
               
          </v-col>
          <!-- <v-col cols="12" sm="12" md="12" lg="12" xl="12 " style="display: flex; justify-content: center; ">
           
                <v-row >
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6 ">
                <div class="regulados-por">Regulados por&nbsp;</div>
              </v-col>
              <v-col cols="3" sm="3" md="3" lg="3" xl="3 " style="display: flex; justify-content: right ">
                <img class="dise-o-sin-t-tulo-27-1" src="/assets/dise-o-sin-t-tulo-27-10.png">
              </v-col>
                <v-col cols="3" sm="3" md="3" lg="3" xl="3 " style="display: flex; justify-content: left; ">
                <img class="dise-o-sin-t-tulo-29-1" src="/assets/dise-o-sin-t-tulo-29-10.png">
              </v-col>
              </v-row>
            
          </v-col> -->

        </v-row>

      </v-col>
      <v-col cols="12" sm="12" md="6" lg="2" xl="2 " style="display: flex; justify-content: center; margin-top:10px ">
        <div class="mueve-seguro-ingresa-registrate">
          <span>
            <span class="mueve-seguro-ingresa-registrate-span">
              Mueve Seguro
              <br>
            </span>
            <span class="mueve-seguro-ingresa-registrate-span2"><br></span>
            <span class="mueve-seguro-ingresa-registrate-span3">
              Ingresa
              <br>
              Registrate
            </span>
          </span>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="2" xl="2" style="display: flex; justify-content: center;; margin-top:10px ">
        <div class="seguro-cotiza-beneficios-qu-es">
          <span>
            <span class="seguro-cotiza-beneficios-qu-es-span">
              Seguro
              <br>
            </span>
            <span class="seguro-cotiza-beneficios-qu-es-span2"><br></span>
            <span class="seguro-cotiza-beneficios-qu-es-span3">
              Cotiza
              <br>
              ¿Qué es?
            </span>
            <span class="seguro-cotiza-beneficios-qu-es-span2"><br></span>
            <br>
            <span class="seguro-bases-span3" @click="bases">
              Bases Legales
            </span>
          </span>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="3" xl="3 " style="display: flex; justify-content: center;; margin-top:10px   ">
        <div class="group-34">
          <div class="group-32">
            <div class="contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl">
              <span>
                <span class="contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span">
                  Contacto
                  <br>
                </span>
                <span class="contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span2">
                  Consultas de la portabilidad: Servicioalcliente@mueveseguro.cl
                  Consulta de tu nuevo Seguro: contacto@grupoinsurex.cl
                 
                </span>
              </span>
            </div>
          </div>
          <!-- <img class="group-33" src="/assets/group-330.svg"> -->
          <v-row class="row3 colcenter">
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="face" id="btn-social-facebook">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>

            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="insta" id="btn-social-instagram">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>

            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="link" id="btn-social-linkedin">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2" xl="2">
              <v-btn small color="#04137f" fab dark @click="you" id="btn-social-youtube">
                <v-icon>mdi-youtube</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="2" xl="2" style="display: flex; justify-content: center;; margin-top:10px ">
        <div class="sobre-nosotros-somos-misi-n-visi-n-valores" @click="somos">
          <span>
            <span class="sobre-nosotros-somos-misi-n-visi-n-valores-span">
              Quiénes somos
              <br>
            </span>
            <span class="sobre-nosotros-somos-misi-n-visi-n-valores-span2">
              
              Mueve Seguro es una empresa de Grupo Insurex.
             
              Lee más sobre nosotros, nuestros valores y misión acá.
             
            </span>
          </span>
        </div>
      </v-col>
      <BotonWsp></BotonWsp>
      <BotonClick></BotonClick>
    </v-row>
</template>

<script>
import BotonWsp from "./BotonWsp.vue";
import BotonClick from "./BotonClick.vue";

export default {
  components: {
    BotonWsp: BotonWsp,
    BotonClick: BotonClick,
  },
  data() {
    return {
      
    }
  },
  methods :{
    face() {
      window.open("https://www.facebook.com/profile.php?id=61555367385086", "");
    },
    insta() {
      window.open("https://www.instagram.com/mueveseguro/", "");
    },
    link() {
      window.open("https://www.linkedin.com/company/grupo-insurex/?viewAsMember=true", "");
    },
    you() {
      window.open("https://www.youtube.com/@mueveseguro", "");
    },
    somos(){
      // this.$router.push("/nosotros");
      location.href = "/nosotros";
    },
    bases(){
      location.href = "https://landing.mueveseguro.cl/bases-legales";
    },
  },

  metaInfo: {
    title: 'Mueve Seguro',
  },
}
</script>

<style scoped>
.una-empresa-de {
  color: var(--azul-complementario, #04137f);
  text-align: left;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  /* position: absolute; */
  /* left: 0px;
  /* top: 11.12px;  */
  width: 100%;
  /* height: 53px; */
  display: flex;
  align-items: center;
  justify-content: center;

}

.logotipo-grupo-insurex-blanco-1 {
  width: 150px;
  width: 150px;
  height: 60.25px;
  /* height: 75.25px; */
  /* position: absolute; */
  /* left: 160px; */
  /* top: 0px; */
  /* object-fit: cover; */
}

.frame-39 {
  display: flex;
  /* flex-direction: row; */
  gap: 11px;
  /* align-items: flex-end; */
  justify-content: center;
  /* flex-wrap: wrap; */
  width: 100%;
  height: 100%;
  position: relative;
  /* left: 48px;
  top: 97px; */
}

.group-392 {
  flex-shrink: 0;
  width: 100%;
  height: 48px;
  /* position: static; */
  display: flex;
  justify-content: center;
}

.regulados-por {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  position: relative;
  /* left: 0px; */
  /* top: -11px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dise-o-sin-t-tulo-27-1 {
  width: 69px;
  height: 48px;
  position: relative;
  /* left: 156px; */
  top: -6px;
  /* object-fit: cover; */
}

.dise-o-sin-t-tulo-29-1 {
  width: 74px;
  height: 14px;
  position: relative;
  /* left: 233px; */
  top: 6px;
  /* object-fit: cover; */
}

.mueve-seguro-ingresa-registrate {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 87%;
  height: 150px;
}

.mueve-seguro-ingresa-registrate-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.mueve-seguro-ingresa-registrate-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.mueve-seguro-ingresa-registrate-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.seguro-cotiza-beneficios-qu-es {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 87%;
  height: 150px;
}

.seguro-cotiza-beneficios-qu-es-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.seguro-cotiza-beneficios-qu-es-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h5-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h5-font-size, 24px);
  line-height: var(--headings-h5-line-height, 28px);
  font-weight: var(--headings-h5-font-weight, 400);
}

.seguro-cotiza-beneficios-qu-es-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: absolute;
  /* left: 1084px;
  top: 26px; */
  width: 308px;
  height: 180px;
}

.contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
}

.contacto-comun-cate-con-nosotros-en-servicioalcliente-mueveseguro-cl-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
}

.group-34 {
  flex-shrink: 0;
  width: 308px;
  height: 180px;
  position: static;
  text-align: center;
}

.group-32 {
  width: 308px;
  height: 120px;
  position: static;
  margin-bottom: 20px;
}

.group-33 {
  width: 211.8px;
  height: 43.8px;
  position: relative;
  /* left: 1133px;
  top: 142px; */
  overflow: visible;
}

.sobre-nosotros-somos-misi-n-visi-n-valores {
  color: var(--azul-complementario, #04137f);
  text-align: center;
  position: relative;
  width: 87%;
  height: 180px;
}

.seguro-bases-span3 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 20px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  cursor: pointer;
}
.seguro-bases-span3:hover{
  color: var(--azul-complementario, #1d2eff);
  transition: background-color 0.5s;
}

.sobre-nosotros-somos-misi-n-visi-n-valores-span:hover{
  color: var(--azul-complementario, #1d2eff);
  transition: background-color 0.5s;
}
.sobre-nosotros-somos-misi-n-visi-n-valores-span2:hover{
  color: var(--azul-complementario, #1d2eff);
  transition: background-color 0.5s;
}
.sobre-nosotros-somos-misi-n-visi-n-valores-span {
  color: var(--azul-complementario, #04137f);
  font-family: "CircularPro-Black", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  cursor: pointer;
}

.sobre-nosotros-somos-misi-n-visi-n-valores-span2 {
  color: var(--azul-complementario, #04137f);
  font-family: var(--headings-h6-font-family, "CircularPro-Book", sans-serif);
  font-size: var(--headings-h6-font-size, 16px);
  line-height: var(--headings-h6-line-height, 24px);
  font-weight: var(--headings-h6-font-weight, 400);
  text-align: justify;
  cursor: pointer;
}

.btn-contrata {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.navapp {
  box-shadow: none !important;
}

.home-logotipo-mueve-seguroconbajada3 {
  width: 325px;
}

.avrow3 {
  margin-top: 30px;
}

.bannerIz {
  padding: 60px;
}

.bannerCn {
  /* padding: 80px; */
  display: flex;
  justify-content: center;
  padding-top: 0px !important;
}

.bannerDr {
  padding: 60px;
}

.row3 {
  background: var(--blanco, #f0f2f2);
  display: flex !important;
  justify-content: center !important;
}

@media only screen and (max-width: 800px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
    font-size: 30px;
    line-height: 72px;
    font-weight: 900;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  /* .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h1-font-size, 64px);
  line-height: var(--headings-h1-line-height, 72px);
  font-weight: var(--headings-h1-font-weight, 700);
  position: relative;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
} */

  .bannerIz {
    /* padding: 50px; */
  }

  .bannerCn {
    /* padding: 80px; */
    display: flex;
    justify-content: center;
    padding-top: 60px;
  }

  .piggy-1 {
    flex-shrink: 0;
    width: 388px;
    height: 388px;
    /* position: relative; */
    object-fit: cover;
    align-content: center;
    margin-top: 0px !important;

  }



  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0px !important;
   
  }
}

.menu-t {
  display: unset !important;
}

.menu-m {
  display: none !important;
}

.mdi-menu {
  margin-top: 45px !important;
  font-size: 53px !important;
  color: rgba(4, 19, 127, 1) !important;
}

@media only screen and (max-width: 1380px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
    font-size: 35px;
    line-height: 72px;
    font-weight: 900;
    position: relative;
    
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }

  .bannerlogo {
  background-image: url('/assets/teaser-ms-02-10.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
  margin-left: 0px !important;
}

}
  /* .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
  color: var(--blanco, #f0f2f2);
  text-align: left;
  font-family: var(--headings-h1-font-family, "CircularPro-Bold", sans-serif);
  font-size: var(--headings-h1-font-size, 64px);
  line-height: var(--headings-h1-line-height, 72px);
  font-weight: var(--headings-h1-font-weight, 700);
  position: relative;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
} */

  .bannerIz {
    padding: 20px;
    padding-top: 50px;
  }

  .menu-t {
    display: none !important;
  }

  .menu-m {
    display: unset !important;
  }
  .rotateimage{
  display : none !important;
  }
  .circuloiz{
  display: none !important;
  }
  .circulodr{
  display: none !important;
  }



.colcenter {
  display: flex;
  justify-content: center;
}

.rowpage2 {
  background-color: #f0f2f2;
}
.pdrotate{
  padding: 20px;
}
.rotate-container{
  align-self: self-start;
}
.footerMueve{
  position: fixed ;
  bottom: 0 ;
  width: 100% ;
}
.v-application{
  background-color: #f0f2f2 !important;
}
</style>