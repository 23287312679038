<template>

  <nav>
    <v-app-bar color="#f0f2f2" dark prominent class="navapp">
      <div class="menu-m">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="menu-m"></v-app-bar-nav-icon>
      </div>

      <!-- <v-toolbar-title>My files</v-toolbar-title> -->
      <v-toolbar-title>
        <img @click="home" src="/external/logotipomueveseguroconbajada31318-kuw-200h.png"
          alt="LogotipoMueveSeguroconbajada31318" class="home-logotipo-mueve-seguroconbajada3">
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="menu-t">
        <div class="d-flex flex-column flex-md-row justify-end mt-8 ">

         <!-- <v-btn text class=""><span class="home-text HeadingsH6"><span>Beneficios</span></span></v-btn>-->
          <v-btn text class=""><span class="home-text HeadingsH6" @click="como"><span>¿Cómo
                funciona?</span></span></v-btn>
          <v-btn text class=""><span class="home-text HeadingsH6" @click="nosotros"><span>¿Quiénes somos?</span></span></v-btn>
          <v-btn text class=""><span class="home-text HeadingsH6" @click="preguntas"><span>Preguntas
                frecuentes</span></span></v-btn>
         <v-btn text class=""><span class="home-text HeadingsH6" @click="otroSeguros"><span>SOAP/Asistencia en viaje</span></span></v-btn>
          <v-btn outlined rounded color="#04137f" large class="mr-2 btntop" @click="login"><span
              class="home-text txtbnt"><span>Ingresar</span></span></v-btn>
          <v-btn rounded class=" txtbnt2"><span class="home-text txtbnt3" @click="calcula"><span>Calcula tu
                devolución</span></span></v-btn>
        </div>
      </div>

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
  
      <v-btn icon>
        <v-icon>mdi-filter</v-icon>
      </v-btn>
  
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute class="indices">
      <v-list nav>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
        <!--  <v-list-item>
            <v-list-item-title>Beneficios</v-list-item-title>
          </v-list-item> -->

          <v-list-item>
            <v-list-item-title @click="como">¿Cómo funciona?</v-list-item-title>
          </v-list-item>
          <v-list-item>
          <v-list-item-title @click="nosotros">¿Quiénes somos?</v-list-item-title>
        </v-list-item>
          <v-list-item>
            <v-list-item-title @click="preguntas">Preguntas frecuentes</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="otroSeguros">SOAP/Asistencia en viaje</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="display:flex;justify-content:center"><v-btn outlined rounded @click="login"
                class="mr-1 btntop"><span
                  class="home-text txtbnt"><span>Ingresar</span></span></v-btn></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="display:flex;justify-content:center"><v-btn rounded class="mr-1 txtbnt2" @click="calcula"><span
                  class="home-text txtbnt3"><span>Calcula tu devolución</span></span></v-btn></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      drawer: false,
      group: null,
    }
  },
  methods: {
    nosotros(){
      // this.$router.push("/nosotros");
      // location.href = "https://www.grupoinsurex.cl/";
      window.open('https://www.grupoinsurex.cl','_blank');
    },
    home() {
      this.$router.push("/");
    },
    como() {
      location.href = "/#cfunciona";
    },
    somos() {
      location.href = "/nosotros";
    },
    preguntas() {
      this.$router.push("/preguntas");
    },
    login() {
      location.href = "/login";
    },
    calcula() {
      location.href = "https://landing.mueveseguro.cl/calculadora?origin=web";
    },
    otroSeguros(){
      location.href = "https://landing.mueveseguro.cl/otros-seguros";
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>
<style scoped>
.navapp {
  box-shadow: none !important;
}

.menu-m {
  display: none !important;
}

.menu-t {
  display: unset !important;
}

.mdi-menu {
  margin-top: 45px !important;
  font-size: 53px !important;
  color: rgba(4, 19, 127, 1) !important;
}

.home-logotipo-mueve-seguroconbajada3 {
  width: 300px;
  cursor: pointer;
}

.HeadingsH6 {
  font-size: 13px;
  font-style: normal;
  font-family: Circular Pro;
  font-weight: 300px;
  font-stretch: normal;
  letter-spacing: 0em;
  margin-top: 21px !important;
}

.home-text {
  top: 4px;
  color: rgba(92, 94, 116, 1);
  /* width: 100px; */
  height: auto;
  /* position: absolute; */
  text-align: center;
  line-height: 24px;
  font-family: var(--headings-h2-font-family, "CircularPro-Bold", sans-serif);
}

.btntop {
  margin-top: 3px;
}
.txtbnt{
  font-size: 12px !important;
}
.txtbnt2 {
  font-size: 10px !important;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  background-color: rgba(4, 19, 127, 1) !important;
}

.txtbnt3 {
  font-size: 11px !important;
  padding-top: 26px !important;
  ;
  padding-bottom: 26px !important;
  ;
  /* background-color: rgba(4, 19, 127, 1) !important; */
  color: white !important;
}

@media only screen and (max-width: 800px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    font-size: 30px;
    line-height: 72px;
    font-weight: 900;
    position: relative;
    /* width: 800px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .bannerIz {
    /* padding: 50px; */
  }

  .bannerCn {
    /* padding: 80px; */
    display: flex;
    justify-content: center;
    padding-top: 60px;
  }

  .piggy-1 {
    flex-shrink: 0;
    width: 388px;
    height: 388px;
    /* position: relative; */
    object-fit: cover;
    align-content: center;
    margin-top: 0px !important;

  }

  .home-logotipo-mueve-seguroconbajada3 {
    width: 300px !important;
  }

  .navapp {
    height: 110px !important;
  }

  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0px !important;

  }
}

@media only screen and (max-width: 1380px) {
  .la-portabilidad-llega-al-mundo-de-los-seguros-de-desgravamen {
    color: var(--blanco, #f0f2f2);
    text-align: left;
    font-family: "CircularPro-Black", sans-serif;
    font-size: 35px;
    line-height: 72px;
    font-weight: 900;
    position: relative;
    /* width: 800px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .bannerIz {
    padding: 20px;
    padding-top: 50px;
  }

  .menu-t {
    display: none !important;
  }

  .menu-m {
    display: unset !important;
  }

  .rotateimage {
    display: none !important;
  }

  .circuloiz {
    display: none !important;
  }

  .circulodr {
    display: none !important;
  }

  .indices {
    z-index: 1000;
  }


}
</style>